import {
  Form,
  SelectField,
  Rules,
  InputNumber,
  useFormContext,
} from "../../form";
import {
  ActivityDetailsFragment,
  CurrentTenantFragment,
  UnitType,
  WorkOrderCategory,
  WorkOrderType,
} from "../../../lib/graphql";
import {
  useActivityCategoryOptions,
  useUnitOptions,
  useActivityProgressMeasureTypes,
  useActivityLaborForceTypes,
  useWorkOrderTypeOptions,
  allowedUnitType,
  useLocaleNameField,
  useCurrentUser,
} from "../../../lib/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { Input, Radio, Tooltip, Divider, Col, Row } from "antd";
import { LockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  formatEntityNameLabel,
  rtfValues,
  shouldUpdate,
  translate,
} from "../../../lib/formats";
import { HelpTooltip, Popover } from "../../shared";
import { LocalityFormSelect } from "../localities";
import { CropSelect } from "..";
import { LocaleEmoji, TranslationsInput } from "../../users";
import { ActivityMetrics } from "./ActivityMetrics";
import { ActivityFormSidebar } from "./ActivityFormSidebar";
import { useEffect } from "react";
import { useEffectOnce } from "react-use";
import { groupBy } from "lodash";

export function ActivityBasicForm({
  activity,
}: {
  activity?: ActivityDetailsFragment;
}) {
  const { unitOptions } = useUnitOptions();
  const { options: activityProgressMeasureTypeOptions } =
    useActivityProgressMeasureTypes();
  const { options: activityLaborForceTypeOptions } =
    useActivityLaborForceTypes();
  const { form } = useFormContext();
  const intl = useIntl();
  const { currentTenant } = useCurrentUser();
  const localeNameField = useLocaleNameField();

  const timeUnit = unitOptions.find((o) => o.unit.unitType === UnitType.Time);

  useEffect(() => {
    if (activity?.id || !timeUnit) return;

    form.setFields([
      {
        name: "progressUnitId",
        value: timeUnit.key,
      },
    ]);
  }, [activity?.id, timeUnit, form]);

  return (
    <>
      <HelpTooltip
        title={
          <FormattedMessage
            id="activities.nameHelp"
            defaultMessage="nameHelp"
          />
        }
      >
        <Form.Item
          name={activity ? localeNameField : "name"}
          label={formatEntityNameLabel(
            <FormattedMessage
              id="activities.entityName"
              defaultMessage="activity"
            />
          )}
          rules={[Rules.required]}
          required
        >
          <Input prefix={<LocaleEmoji.Current />} />
        </Form.Item>
      </HelpTooltip>

      <HelpTooltip
        title={
          <FormattedMessage
            id="activities.categoryHelp"
            defaultMessage="categoryHelp"
          />
        }
      >
        <Form.Item
          name="categoryId"
          label={<FormattedMessage id="activities.category" />}
          rules={[Rules.required]}
          required
        >
          <SelectField optionsHook={useActivityCategoryOptions} />
        </Form.Item>
      </HelpTooltip>

      <Form.Item name="internalId" label={<FormattedMessage id="internalId" />}>
        <Input name="internalId" />
      </Form.Item>

      <Form.Item
        name="description"
        label={<FormattedMessage id="description" />}
      >
        <Input.TextArea rows={3} />
      </Form.Item>

      <WorkOrderTypeFormItem currentTenant={currentTenant} />

      {currentTenant.features.machinery && (
        <HelpTooltip
          title={
            <FormattedMessage
              id="activities.laborForceTypeTooltipHelp"
              defaultMessage="laborForceTypeTooltipHelp"
              values={rtfValues}
            />
          }
          trigger="hover"
        >
          <Form.Item
            name="laborForceType"
            label={<FormattedMessage id="activities.laborForceType" />}
            rules={[Rules.required]}
            required
            extra={
              <FormattedMessage
                id="activities.laborForceTypeHelp"
                defaultMessage="laborForceTypeHelp"
              />
            }
          >
            <Radio.Group
              name="laborForceType"
              options={activityLaborForceTypeOptions}
            />
          </Form.Item>
        </HelpTooltip>
      )}

      {currentTenant.features.advancedActivities && (
        <HelpTooltip
          title={
            <FormattedMessage
              id="activities.workdayHoursTooltipHelp"
              defaultMessage="workdayHoursTooltipHelp"
            />
          }
        >
          <Form.Item
            name="workdayHours"
            label={<FormattedMessage id="activities.workdayHours" />}
            rules={[Rules.required]}
            required
            extra={
              <FormattedMessage
                id="activities.workdayHoursHelp"
                defaultMessage="workdayHoursHelp"
              />
            }
          >
            <InputNumber
              min={1}
              max={24}
              addonAfter={
                <FormattedMessage
                  id="hoursPerDay"
                  defaultMessage="hoursPerDay"
                />
              }
            />
          </Form.Item>
        </HelpTooltip>
      )}

      {(!currentTenant.farmLimit || currentTenant.farmLimit > 1) && (
        <HelpTooltip
          title={
            <FormattedMessage
              id="activities.localitiesTooltipHelp"
              defaultMessage="localitiesTooltipHelp"
            />
          }
        >
          <LocalityFormSelect
            formItemProps={{
              name: "localityIds",
              extra: (
                <FormattedMessage
                  id="activities.localitiesHelp"
                  defaultMessage="localitiesHelp"
                />
              ),
            }}
          />
        </HelpTooltip>
      )}

      {currentTenant.features.advancedActivities && (
        <HelpTooltip
          title={
            <FormattedMessage
              id="activities.cropsTooltipHelp"
              defaultMessage="cropsTooltipHelp"
            />
          }
        >
          <Form.Item
            name="cropIds"
            label={<FormattedMessage id="crops" />}
            extra={
              <FormattedMessage
                id="activities.cropsHelp"
                defaultMessage="cropsHelp"
              />
            }
          >
            <CropSelect
              mode="multiple"
              placeholder={<FormattedMessage id="crops.all" />}
            />
          </Form.Item>
        </HelpTooltip>
      )}

      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.progressUnitId !== next.progressUnitId ||
          prev.workOrderType !== next.workOrderType
        }
      >
        {({ getFieldValue, setFields }) => {
          if (!currentTenant.features.advancedActivities) return;

          const progressUnitId = getFieldValue("progressUnitId");
          const workOrderType = getFieldValue("workOrderType");
          const onlyHours = workOrderType === WorkOrderType.Repair;

          const hideProgress =
            progressUnitId === undefined
              ? true
              : unitOptions.find((o) => o.key == progressUnitId)?.unit
                  .unitType == UnitType.Time || onlyHours;

          const measureProgressRadio = (
            <Radio.Group
              value={hideProgress ? "no" : "yes"}
              disabled={onlyHours}
              options={[
                {
                  value: "yes",
                  label: <FormattedMessage id="yes" defaultMessage="yes" />,
                },
                {
                  value: "no",
                  label: (
                    <FormattedMessage
                      id="activities.measureProgress.byHours"
                      defaultMessage="byHours"
                    />
                  ),
                },
              ]}
              onChange={(e) => {
                setFields([
                  {
                    name: "progressUnitId",
                    value: e.target.value === "no" ? timeUnit?.key : null,
                  },
                ]);
              }}
            />
          );

          return (
            <>
              <Divider>
                <FormattedMessage id="metrics" />
              </Divider>

              <HelpTooltip
                title={
                  <FormattedMessage
                    id="activities.measureProgressHelp"
                    defaultMessage="measureProgressHelp"
                    values={rtfValues}
                  />
                }
                trigger="hover"
              >
                <Form.Item
                  label={
                    <FormattedMessage
                      id="activities.measureProgress"
                      defaultMessage="measureProgress"
                    />
                  }
                  extra={
                    <FormattedMessage
                      id="activities.measureProgress.hint"
                      defaultMessage="measureProgressHint"
                    />
                  }
                >
                  {getFieldValue("workOrderType") === WorkOrderType.Repair ? (
                    <Popover
                      overlayStyle={{ width: "300px" }}
                      content={
                        <FormattedMessage
                          id="activities.lockedMeasureProgress"
                          defaultMessage="lockedMeasureProgress"
                        />
                      }
                    >
                      {measureProgressRadio}
                    </Popover>
                  ) : (
                    measureProgressRadio
                  )}
                </Form.Item>
              </HelpTooltip>

              <div className={hideProgress ? "hide" : ""}>
                <HelpTooltip
                  title={
                    <FormattedMessage
                      id="activities.progressUnitTooltipHelp"
                      defaultMessage="progressUnitTooltipHelp"
                      values={rtfValues}
                    />
                  }
                  trigger="hover"
                >
                  <Form.Item
                    label={<FormattedMessage id="activities.progressUnit" />}
                    extra={
                      <FormattedMessage
                        id="activities.progressUnitHelp"
                        defaultMessage="progressUnitHelp"
                      />
                    }
                    required
                  >
                    {activity?.progressUnitLocked ? (
                      <Popover
                        placement="right"
                        overlayStyle={{ width: "450px" }}
                        content={
                          <FormattedMessage
                            id="activities.lockedProgressUnit"
                            defaultMessage="lockedProgressUnit"
                          />
                        }
                      >
                        <div>
                          <Form.Item name="progressUnitId" noStyle>
                            <SelectField
                              options={unitOptions}
                              disabled
                              suffixIcon={<LockOutlined />}
                            />
                          </Form.Item>
                        </div>
                      </Popover>
                    ) : (
                      <Form.Item
                        name="progressUnitId"
                        rules={[Rules.required]}
                        required
                        noStyle
                      >
                        <SelectField
                          showGroups
                          options={unitOptions.filter((o) =>
                            allowedUnitType(o.unit.unitType)
                          )}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </HelpTooltip>

                <HelpTooltip
                  title={
                    <FormattedMessage
                      id="activities.progressMeasureTypeTooltipHelp"
                      defaultMessage="progressMeasureTypeHelp"
                      values={rtfValues}
                    />
                  }
                  trigger="hover"
                >
                  <Form.Item
                    name="progressMeasureType"
                    label={
                      <FormattedMessage id="activities.progressMeasureType" />
                    }
                    rules={hideProgress ? undefined : [Rules.required]}
                    required
                    extra={
                      <FormattedMessage
                        id="activities.progressMeasureTypeHelp"
                        defaultMessage="progressMeasureTypeHelp"
                      />
                    }
                  >
                    <Radio.Group options={activityProgressMeasureTypeOptions} />
                  </Form.Item>
                </HelpTooltip>

                <Form.Item
                  name="useTotalArea"
                  extra={
                    <Form.Item noStyle shouldUpdate={shouldUpdate("useArea")}>
                      {({ getFieldValue }) =>
                        !getFieldValue("useArea") ? (
                          <FormattedMessage
                            id="activities.useEffectiveAreaHelp"
                            defaultMessage="useEffectiveAreaHelp"
                          />
                        ) : (
                          <FormattedMessage
                            id="activities.useTotalAreaHelp"
                            defaultMessage="useTotalAreaHelp"
                          />
                        )
                      }
                    </Form.Item>
                  }
                >
                  <Radio.Group
                    options={[
                      {
                        label: <FormattedMessage id="cropFields.totalArea" />,
                        value: true,
                      },
                      {
                        label: (
                          <FormattedMessage id="cropFields.effectiveArea" />
                        ),
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
              </div>

              <ActivityMetrics />
            </>
          );
        }}
      </Form.Item>

      <ActivityFormSidebar activity={activity} />

      <TranslationsInput
        key="translations"
        render={(locale) => (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item noStyle name={`name${locale.suffix}`}>
                <Input
                  placeholder={intl.formatMessage({ id: "name" })}
                  prefix={locale.emoji}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      />
    </>
  );
}

function WorkOrderTypeFormItem({
  currentTenant,
}: {
  currentTenant: CurrentTenantFragment;
}) {
  const { options = [], load, loading } = useWorkOrderTypeOptions();

  useEffectOnce(() => {
    load();
  });

  if (
    !currentTenant.features.enabledWorkOrderTypes ||
    currentTenant.features.enabledWorkOrderTypes.length === 1
  )
    return null;

  const helpMessageParts = Object.entries(groupBy(options, (o) => o.category))
    .map(([category, subs]) => {
      if (subs.length && category !== WorkOrderCategory.General) {
        return {
          tip: (
            <FormattedMessage
              id={`activities.workOrderTypeHelp.${category}`}
              defaultMessage={category}
              values={{
                options: subs.length,
                ...rtfValues,
              }}
            />
          ),
          subs: subs.map((o) => (
            <FormattedMessage
              key={o.key}
              id={`activities.workOrderTypeHelp.${category}.${o.key}`}
              defaultMessage={o.label}
            />
          )),
        };
      }
    })
    .filter(Boolean);

  return (
    <HelpTooltip
      title={
        <>
          <FormattedMessage
            id="activities.workOrderTypeHelpContainer"
            defaultMessage="Type of Work Order. In this field there are {options} options:"
            values={{
              options: helpMessageParts.length,
              ...rtfValues,
            }}
          />
          <br />
          <ol
            style={{
              listStyleType: "lower-alpha",
              paddingLeft: "20px",
              margin: 0,
            }}
          >
            {helpMessageParts.map(
              (p, idx) =>
                p && (
                  <li key={idx}>
                    {p.tip}
                    {p.subs && (
                      <ol
                        style={{
                          listStyleType: "decimal",
                          paddingLeft: "20px",
                        }}
                      >
                        {p.subs.map((s) => (
                          <li key={s.key}>{s}</li>
                        ))}
                      </ol>
                    )}
                  </li>
                )
            )}
          </ol>
        </>
      }
    >
      <Form.Item
        name="workOrderType"
        label={
          <Tooltip
            title={
              <FormattedMessage
                id="activities.workOrderType.hint"
                defaultMessage="hint"
              />
            }
          >
            <span>
              <FormattedMessage
                id="activities.workOrderType"
                defaultMessage="workOrderType"
              />
              <InfoCircleOutlined style={{ marginLeft: "8px" }} />
            </span>
          </Tooltip>
        }
        rules={[Rules.required]}
        extra={
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.workOrderType !== next.workOrderType
            }
          >
            {({ getFieldValue }) => {
              const workOrderType = getFieldValue("workOrderType");
              return (
                workOrderType &&
                translate(`activities.workOrderTypes.${workOrderType}`)
              );
            }}
          </Form.Item>
        }
      >
        <SelectField showGroups loading={loading} options={options} />
      </Form.Item>
    </HelpTooltip>
  );
}
