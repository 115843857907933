import { FormattedMessage } from "react-intl";
import { Space, TableInput } from "../../shared";
import {
  ActivityMetricScope,
  MachineCategory,
  PositionType,
  WorkOrderMachineFragment,
} from "../../../lib/graphql";
import {
  formatEmployeeName,
  formatEmployeeOption,
  formatUnitValue,
  formatHours,
  shouldUpdate,
  shouldUpdateBy,
  formatMachine,
  formatInfoTooltip,
  formatNumber,
} from "../../../lib/formats";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMachineOptions } from "../../../lib/hooks";
import {
  Form,
  SelectField,
  Rules,
  InputNumber,
  RuleBuilder,
  DeepNamePath,
  InputNumberProps,
  TimeRangePickerFormItem,
} from "../../form";
import Table, { ColumnsType } from "antd/lib/table";
import { WorkOrderContext } from "./WorkOrderContext";
import { ItemFieldFormConfig } from "../../shared/ListView/ItemForm";
import { filterColumns } from "../../../lib/utils";
import { Button, Typography } from "antd";
import dayjs from "dayjs";
import { useWatch } from "antd/lib/form/Form";

export function WorkOrderMachines() {
  const { workOrder, builder } = useContext(WorkOrderContext);
  const localityId = [workOrder.locality.id];

  const editFields: ItemFieldFormConfig<WorkOrderMachineFragment>[] = useMemo(
    () => [
      {
        label: <FormattedMessage id="hours" />,
        type: "custom",
        render: () => (
          <Form.Item
            compact
            key="hours"
            name={"workHours"}
            rules={[Rules.onlyNumbers, RuleBuilder.gtEq(0)]}
          >
            <InputNumber
              step={1}
              min={0}
              max={24}
              addonAfter={<FormattedMessage id="workUnits.abbr.hour" />}
            />
          </Form.Item>
        ),
      },
    ],
    []
  );

  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdateBy(
        (val) => val.employees.filter(Form.undestroyed).length,
        (val) => val.machines.filter(Form.undestroyed).length
      )}
    >
      {({ getFieldValue }) => {
        const employees = builder.employees.get();
        const operatorOptions = employees
          .filter(
            (e) => e.employee.position.positionType === PositionType.Machinery
          )
          .map((e) => ({
            key: e.employee.id,
            label: formatEmployeeOption(e.employee),
          }));

        builder.machines.initMachineMetrics();

        const columns: ColumnsType<WorkOrderMachineFragment> = filterColumns([
          {
            title: <FormattedMessage id="machines.entityName" />,
            dataIndex: ["machine", "id"],
            render: (_, m) => formatMachine(m.machine),
          },
          {
            title: (
              <FormattedMessage
                id="machines.implement"
                defaultMessage="implement"
              />
            ),
            dataIndex: ["implement", "name"],
            render: (_, m, index) => {
              if (m.machine.kind.category !== MachineCategory.Agricultural) {
                return null;
              }

              return builder.isReadonly ? (
                m.implement?.name
              ) : (
                <Form.Item
                  compact
                  name={["machines", index, "implement", "id"]}
                >
                  <SelectField
                    optionsHook={useMachineOptions}
                    optionsHookParams={{
                      variables: {
                        filter: {
                          category: [MachineCategory.Implements],
                          localityId,
                        },
                      },
                    }}
                    bordered={false}
                    placeholder={
                      <FormattedMessage
                        id="select.implement"
                        defaultMessage="implement"
                      />
                    }
                  />
                </Form.Item>
              );
            },
          },
          {
            title: (
              <FormattedMessage
                id="machines.operator"
                defaultMessage="operator"
              />
            ),
            dataIndex: ["operator"],
            render: (_, m, index) =>
              builder.isReadonly
                ? formatEmployeeName(m.operator)
                : m.machine.kind.category !== MachineCategory.Portables && (
                    <Form.Item
                      compact
                      name={["machines", index, "operator", "id"]}
                    >
                      <SelectField
                        dropdownMatchSelectWidth={240}
                        emptyProps={{
                          image: "/images/icons/machinery-operator.svg",
                          imageStyle: {
                            margin: "auto",
                            width: "50%",
                            height: "64px",
                          },
                          description: (
                            <FormattedMessage
                              id="machines.operator.empty"
                              defaultMessage="No Machine Operator found in Manual Labor section above."
                            />
                          ),
                        }}
                        options={operatorOptions}
                        bordered={false}
                        placeholder={
                          <FormattedMessage
                            id="select.employee"
                            defaultMessage="employee"
                          />
                        }
                      />
                    </Form.Item>
                  ),
          },
          builder.isSpraying &&
            builder.machines.useInstructions() && {
              title: (
                <FormattedMessage
                  id="workOrders.machines.instructions"
                  defaultMessage="Instructions"
                />
              ),
              width: "10rem",
              render: (_, _m, index) => <MachineInstructions index={index} />,
            },
          builder.isSpraying &&
            builder.machines.useApplicator() && {
              title: (
                <FormattedMessage
                  id="workOrders.machines.applicator"
                  defaultMessage="Applicator"
                />
              ),
              width: "12rem",
              render: (_, _m, index) => <MachineApplicator index={index} />,
            },
          builder.machines.useOdometer() && {
            title: (
              <FormattedMessage
                id="workOrders.machines.startOdometer"
                defaultMessage="startOdometer"
              />
            ),
            dataIndex: "startOdometer",
            width: 150,
            render: (_, m, index) => {
              if (!builder.machines.useOdometer(m)) return;

              return builder.isReadonly ? (
                formatUnitValue(m.startOdometer, m.machine.odometerUnit)
              ) : (
                <Form.Item
                  compact
                  name={["machines", index, "startOdometer"]}
                  rules={[
                    RuleBuilder.gtEq(m.machine.odometerValue || 0),
                    Rules.onlyIntegers,
                  ]}
                >
                  <InputNumber
                    min={m.machine.odometerValue || 0}
                    step={1}
                    addonAfter={m.machine.odometerUnit?.abbr}
                  />
                </Form.Item>
              );
            },
          },
        ]);

        const showMetrics = builder.isDataIntake;

        const showProgress =
          builder.isDataIntake &&
          builder.isMachinery &&
          builder.allowProgress &&
          builder.progressByIndividual;

        if (builder.isDataIntake) {
          if (builder.machines.useOdometer()) {
            columns.push({
              title: (
                <FormattedMessage
                  id="workOrders.machines.endOdometer"
                  defaultMessage="endOdometer"
                />
              ),
              dataIndex: "endOdometer",
              width: 150,
              render: (_, m, index) => {
                if (!builder.machines.useOdometer(m)) return;

                return builder.isReadonly ? (
                  formatUnitValue(m.endOdometer, m.machine.odometerUnit)
                ) : (
                  <Form.Item
                    compact
                    name={["machines", index, "endOdometer"]}
                    rules={[
                      builder.isMachineryMaintenance ||
                      builder.isTransitAndTransport
                        ? RuleBuilder.gt(m.startOdometer || 0)
                        : RuleBuilder.gtEq(m.startOdometer || 0),
                      Rules.onlyIntegers,
                    ]}
                  >
                    <InputNumber
                      min={m.startOdometer || 0}
                      step={1}
                      addonAfter={m.machine.odometerUnit?.abbr}
                    />
                  </Form.Item>
                );
              },
            });
          }

          columns.push({
            title: <FormattedMessage id="hours" />,
            dataIndex: "workHours",
            width: "12rem",
            render: (_, m, index) => {
              if (
                !m.machine.horometerUnit ||
                m.machine.kind.category === MachineCategory.Portables
              )
                return;

              return builder.isReadonly ? (
                formatUnitValue(m.workHours, m.machine.horometerUnit)
              ) : (
                <MachineWorkHours
                  name={["machines", index]}
                  hourAbbr={m.machine.horometerUnit?.abbr}
                />
              );
            },
          });

          if (showProgress) {
            columns.push({
              title: <FormattedMessage id="progress" />,
              width: "12rem",
              render: (_, m, index) => {
                const name = ["machines", index, "totalProgress"];

                return builder.isReadonly || builder.progressByGroup ? (
                  <Form.Item noStyle shouldUpdate={shouldUpdate(name)}>
                    {() =>
                      formatUnitValue(
                        getFieldValue(name),
                        workOrder.activity.progressUnit
                      )
                    }
                  </Form.Item>
                ) : (
                  <Form.Item compact name={name} rules={[Rules.gtEqZero]}>
                    <InputNumber
                      step={0.1}
                      min={0}
                      addonAfter={workOrder.activity.progressUnit.abbr}
                      onChange={(val) =>
                        builder.machines.progress.debouncedDistributeTotalProgress(
                          m,
                          val
                        )
                      }
                    />
                  </Form.Item>
                );
              },
            });
          }
        } else {
          columns.push({
            title: <FormattedMessage id="machines.horometer" />,
            dataIndex: "horometer",
            render: (_, _m, index) => (
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) =>
                  prev.machines[index] != next.machines[index]
                }
              >
                {() => {
                  const machine = getFieldValue(["machines", index]);
                  return formatHours(machine.machine.horometerValue);
                }}
              </Form.Item>
            ),
          });
        }

        if (showMetrics) {
          const metricCols: ColumnsType<WorkOrderMachineFragment> =
            workOrder.activity.activityMetrics
              .filter((m) => m.scope == ActivityMetricScope.Machine)
              .map((m) => ({
                title: (
                  <>
                    {m.metric.name} {formatInfoTooltip(m.metric.description)}
                  </>
                ),
                width: "12rem",
                render: (_, e) => {
                  const name = [
                    "machines",
                    builder.machines.indexOf(e),
                    m.metric.id,
                  ];

                  if (builder.isReadonly) {
                    return formatUnitValue(getFieldValue(name), m.metric.unit);
                  }

                  return (
                    <Form.Item name={name} rules={[Rules.gtEqZero]} compact>
                      <InputNumber
                        step={0.1}
                        min={0}
                        onChange={(val) =>
                          builder.machines.metrics.debouncedDistributeIndividualMetrics(
                            m.metric.id,
                            e.machine.id,
                            val
                          )
                        }
                        addonAfter={m.metric.unit.abbr}
                      />
                    </Form.Item>
                  );
                },
              }));

          columns.push(...metricCols);
        }

        return (
          <TableInput
            name="machines"
            rules={[Rules.required]}
            tableProps={{
              bordered: true,
              summary: (machines) => {
                if (!machines.length) return;

                return (
                  builder.isDataIntake && (
                    <Form.Item
                      noStyle
                      shouldUpdate={shouldUpdate("machines", "costCenters")}
                    >
                      {() => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell
                            index={0}
                            colSpan={builder.isReadonly ? 4 : 5}
                          >
                            <FormattedMessage
                              id="total"
                              defaultMessage="total"
                            />
                          </Table.Summary.Cell>
                          {builder.machines.useOdometer() && (
                            <>
                              <Table.Summary.Cell index={1} />
                              <Table.Summary.Cell index={1} />
                            </>
                          )}
                          <Table.Summary.Cell index={1}>
                            {formatHours(builder.machines.getTotalHours())}
                          </Table.Summary.Cell>
                          {showProgress && (
                            <Table.Summary.Cell index={2}>
                              {formatUnitValue(
                                builder.machines.getTotalProgress(),
                                workOrder.activity.progressUnit
                              )}
                            </Table.Summary.Cell>
                          )}
                          {showMetrics &&
                            workOrder.activity.activityMetrics
                              .filter(
                                (m) => m.scope == ActivityMetricScope.Machine
                              )
                              .map((m) => (
                                <Table.Summary.Cell index={6} key={m.id}>
                                  {formatUnitValue(
                                    builder.machines.metrics.getTotalMetric(
                                      m.metric.id
                                    ),
                                    m.metric.unit
                                  )}
                                </Table.Summary.Cell>
                              ))}
                          {!builder.isReadonly && (
                            <Table.Summary.Cell index={3} />
                          )}
                        </Table.Summary.Row>
                      )}
                    </Form.Item>
                  )
                );
              },
            }}
            dataSource={workOrder.machines}
            rowKey={(m) => m.machine.id}
            disabled={builder.isReadonly}
            onRemove={(m) => builder.machines.onRemove(m)}
            bulkUpdateFields={editFields}
            allowBulkRemove
            tableSelectProps={{
              mode: "multiple",
              showGroups: true,
              optionsHook: useMachineOptions,
              optionsHookParams: { variables: { filter: { localityId } } },
              optionsHookFilter: (o) =>
                builder.isMachineryMaintenance
                  ? true
                  : o.category !== MachineCategory.Implements,
              placeholder: (
                <FormattedMessage
                  id="select.machine"
                  defaultMessage="machines"
                />
              ),
              entityById: (_, { machine }) => {
                if (machine) {
                  return {
                    id: "",
                    machine,
                    operator: null,
                    implement: null,
                    startOdometer: machine.odometerValue,
                    workHours: 0,
                  };
                }
              },
            }}
            addSorter={(a, b) => builder.machines.sorter(a, b)}
            addIndexColumn
            columns={columns}
          />
        );
      }}
    </Form.Item>
  );
}

export function MachineWorkHours({
  name,
  hourAbbr,
  onChange,
}: {
  name: DeepNamePath;
  hourAbbr: string;
} & Pick<InputNumberProps, "onChange">) {
  const [showTime, setShowTime] = useState(false);
  const { workOrder } = useContext(WorkOrderContext);
  const form = Form.useFormInstance();

  const workHoursName = name.concat("workHours");
  const startName = name.concat("startTime");
  const endName = name.concat("endTime");

  const workHours = useWatch(workHoursName);

  useEffect(() => {
    if (showTime && !form.getFieldValue(startName)) {
      form.setFieldValue(startName, workOrder.documentDate);
      form.setFieldValue(
        endName,
        dayjs(workOrder.documentDate).add(workHours || 0, "hours")
      );
    }

    if (form.getFieldValue(startName) && !showTime) {
      form.setFieldValue(
        endName,
        dayjs(form.getFieldValue(startName)).add(workHours || 0, "hours")
      );
    }
  }, [workHours, form, showTime, workOrder.documentDate, startName, endName]);

  const switchButton = (
    <Button
      type="link"
      size="small"
      style={{ padding: 0 }}
      onClick={() => setShowTime((state) => !state)}
    >
      ⏱️
    </Button>
  );

  if (showTime) {
    return (
      <TimeRangePickerFormItem
        startName={startName}
        endName={endName}
        addonBefore={switchButton}
        addonAfter={
          <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
            {formatUnitValue(workHours, { abbr: hourAbbr })}
          </Typography.Text>
        }
        onChange={(values) => {
          if (!values || !values.length) return;

          const hours = dayjs(values[1]).diff(values[0], "hours", true);
          form.setFieldValue(workHoursName, hours);

          onChange && onChange();
        }}
      />
    );
  }

  return (
    <Form.Item
      compact
      name={workHoursName}
      rules={[Rules.required, Rules.onlyNumbers, RuleBuilder.gtEq(0)]}
    >
      <InputNumber
        step={1}
        min={0}
        max={24}
        addonBefore={switchButton}
        addonAfter={hourAbbr}
        onChange={onChange}
      />
    </Form.Item>
  );
}

function MachineInstructions({ index }: { index: number }) {
  const { builder } = useContext(WorkOrderContext);
  const formItemProps = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const machine = builder.machines.getBy(index);

  if (builder.isReadonly) {
    return (
      <Space direction="vertical">
        {builder.machines.showCalibration(machine, "gear") && (
          <Typography.Text>
            <FormattedMessage
              id="workOrders.machines.gear"
              defaultMessage="Gear"
            />
            : {formatNumber(machine.gear)}
          </Typography.Text>
        )}

        {builder.machines.showCalibration(machine, "speed") && (
          <Typography.Text>
            <FormattedMessage
              id="workOrders.machines.speed"
              defaultMessage="Speed"
            />
            : {formatNumber(machine.speed)}
          </Typography.Text>
        )}

        {builder.machines.showCalibration(machine, "rpm") && (
          <Typography.Text>
            <FormattedMessage
              id="workOrders.machines.rpm"
              defaultMessage="RPM"
            />
            : {formatNumber(machine.rpm)}
          </Typography.Text>
        )}
      </Space>
    );
  }

  return (
    <Space direction="vertical">
      {builder.machines.showCalibration(machine, "gear") && (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.machines.gear"
              defaultMessage="Gear"
            />
          }
          name={["machines", index, "gear"]}
          rules={[Rules.onlyIntegers]}
          {...formItemProps}
          compact
        >
          <InputNumber />
        </Form.Item>
      )}

      {builder.machines.showCalibration(machine, "speed") && (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.machines.speed"
              defaultMessage="Speed"
            />
          }
          name={["machines", index, "speed"]}
          rules={[Rules.onlyIntegers]}
          {...formItemProps}
          compact
        >
          <InputNumber />
        </Form.Item>
      )}

      {builder.machines.showCalibration(machine, "rpm") && (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.machines.rpm"
              defaultMessage="RPM"
            />
          }
          name={["machines", index, "rpm"]}
          rules={[Rules.onlyIntegers]}
          {...formItemProps}
          compact
        >
          <InputNumber />
        </Form.Item>
      )}
    </Space>
  );
}

function MachineApplicator({ index }: { index: number }) {
  const { builder } = useContext(WorkOrderContext);
  const formItemProps = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  const machine = builder.machines.getBy(index);

  if (builder.isReadonly) {
    return (
      <Space direction="vertical">
        {builder.machines.showCalibration(machine, "pressure") && (
          <Typography.Text>
            <FormattedMessage
              id="workOrders.machines.pressure"
              defaultMessage="Pressure"
            />
            : {formatNumber(machine.pressure)}
          </Typography.Text>
        )}

        {builder.machines.showCalibration(machine, "nozzle") && (
          <Typography.Text>
            <FormattedMessage
              id="workOrders.machines.nozzle"
              defaultMessage="Nozzle"
            />
            : {formatNumber(machine.nozzle)}
          </Typography.Text>
        )}
      </Space>
    );
  }

  return (
    <Space direction="vertical">
      {builder.machines.showCalibration(machine, "pressure") && (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.machines.pressure"
              defaultMessage="Pressure"
            />
          }
          name={["machines", index, "pressure"]}
          rules={[Rules.onlyIntegers]}
          {...formItemProps}
          compact
        >
          <InputNumber
            addonAfter={
              <FormattedMessage
                id="workOrders.machines.psi"
                defaultMessage="PSI"
              />
            }
          />
        </Form.Item>
      )}

      {builder.machines.showCalibration(machine, "nozzle") && (
        <Form.Item
          label={
            <FormattedMessage
              id="workOrders.machines.nozzle"
              defaultMessage="Nozzle"
            />
          }
          name={["machines", index, "nozzle"]}
          rules={[Rules.onlyIntegers]}
          {...formItemProps}
          compact
        >
          <InputNumber />
        </Form.Item>
      )}
    </Space>
  );
}
