import { FormattedMessage } from "react-intl";
import { evictCache, useInventoryOrderClone } from "../../../lib/hooks";
import { routerPush } from "../../../lib/routes";

import { SidebarHeader } from "../../shared";
import { ItemForm } from "../../shared/ListView/ItemForm";
import { InventoryOrderFragment } from "../../../lib/graphql";
import { formatLink } from "../../../lib/formats";

export function OrderCloneForm({
  item,
  entityName,
  entityUrl,
  onClose,
}: {
  item: InventoryOrderFragment;
  entityName: string;
  entityUrl: (id: string) => string;
  onClose: () => void;
}) {
  const [clone] = useInventoryOrderClone();

  return (
    <>
      <SidebarHeader
        title={
          <FormattedMessage
            id="duplicateEntity"
            defaultMessage="Duplicate {entityName}"
            values={{ entityName }}
          />
        }
        onClose={onClose}
      />

      <ItemForm
        item={item}
        saveText={<FormattedMessage id="cloneOrder" />}
        onCancel={onClose}
        initialValues={{
          documentDate: item.documentDate,
          internalId: item.internalId,
        }}
        formProps={{
          formatError: (error) => formatLink(error, entityUrl),
        }}
        fields={[
          {
            label: <FormattedMessage id="documentDate" />,
            type: "date",
            name: "documentDate",
          },
          {
            label: <FormattedMessage id="internalId" />,
            type: "string",
            name: "internalId",
          },
        ]}
        onSave={(changes) => {
          return clone({
            update: evictCache("inventoryOrders"),
            variables: { id: item.id, changes },
          })
            .then((result) => result.data?.inventoryOrderClone)
            .then((data) => {
              if (data?.result) {
                onClose();
                routerPush(entityUrl(data.result.id));
              }

              return data;
            });
        }}
      />
    </>
  );
}
